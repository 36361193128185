import React from 'react'

export const Footer = () => {
  return (
    <div>
      <footer id="footer">
        <div className="container">
          hascoet.dev
        </div>
      </footer>

    </div>
  )
}
